import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import H2 from '@rotaready/frecl/build/H2';

import ResponsiveContainer from '../layout/ResponsiveContainer';
import RelativeWrapper from '../layout/RelativeWrapper';
import ContentWrapper from '../layout/ContentWrapper';
import NonStretchedImage from '../layout/NonStretchedImage';

import { ViewportBreakpoints } from '../cssConstants';

const BenefitIllustration = styled.div`
  flex: 1 1 100%;
  margin: 60px 0;

  > div {
    width: 95%;
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
    margin: 0;

    > div {
      width: 75%;
    }
  }
`;

const BenefitBody = styled.div`
  flex: 1 1 100%;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
  }
`;

const BodyWrapper = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: ${props => props.mirrorArrange ? '0 0 0 70px' : '0 70px 0 0'};
  }

  @media (min-width: ${ViewportBreakpoints.desktopUpper}) {
    padding: ${props => props.mirrorArrange ? '0 0 0 140px' : '0 140px 0 0'};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: ${props => props.mirrorArrange ? 'row-reverse' : 'row'};
    align-items: center;
  }
`;

const FeatureRow = ({
  backgroundColor,
  mirrorArrange,
  title,
  children,
  illustrationImage,
  illustrationAltText,
}) => (
  <RelativeWrapper backgroundColor={backgroundColor}>
    <ResponsiveContainer>
      <ContentWrapper>
        <InnerContainer mirrorArrange={mirrorArrange}>
          <BenefitBody>
            <BodyWrapper mirrorArrange={mirrorArrange}>
              <H2 uistyle="brand160" text={title} />
              {children}
            </BodyWrapper>
          </BenefitBody>
          <BenefitIllustration>
            <NonStretchedImage
              fluid={illustrationImage}
              alt={illustrationAltText}
            />
          </BenefitIllustration>
        </InnerContainer>
      </ContentWrapper>
    </ResponsiveContainer>
  </RelativeWrapper>
);

FeatureRow.propTypes = {
  backgroundColor: PropTypes.string,
  mirrorArrange: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  illustrationImage: PropTypes.shape({}).isRequired,
  illustrationAltText: PropTypes.string,
};

FeatureRow.defaultProps = {
  backgroundColor: 'white',
  mirrorArrange: false,
  illustrationAltText: '',
};

export default FeatureRow;
