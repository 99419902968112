import styled from 'styled-components';

const HeroH1 = styled.p.attrs({
  children: props => props.children || props.text,
})`
  font-family: ${({ theme: { fonts } }) => fonts.H3.fontFamily};
  font-size: ${({ theme: { fonts } }) => fonts.H3.fontSize}px;
  line-height: ${({ theme: { fonts } }) => fonts.H3.lineHeight}px;
  font-weight: 600;
  color: ${({ theme: { colors }, color }) => colors[color]};
  font-style: normal;
  letter-spacing: normal;
  margin: 0 0 20px 0;
  padding: 0;
`;

export default HeroH1;
